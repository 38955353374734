


























































































import {Vue, Component, Prop, Ref,} from "vue-property-decorator";
import { ImagePreview,Toast } from "vant"
import { computedTime } from "@/util/computedTime"
import Storage from "@/util/Storage"
import FriendComponentsType from "./index"
import {getGive} from "@/views/Friend/FriendCircle/Server";
import {FriendSuccessStore} from "@/views/Friend/FriendSuccess/Store";

@Component({name: "FriendComponents"})
export default class FriendComponents extends Vue implements FriendComponentsType{
    AddressIcon = require("../../Img/FirendAddress.png");
    CartIcon = require("../../FriendCircle/Img/FriendChat.png");
    GiveIcon = require("../../FriendCircle/Img/FirendGive.png");
    NotGiveIcon = require("../../FriendCircle/Img/FirendNotGive .png");
    HideIcon = require("../../FriendCircle/Img/FriendHideTop.png");
    ShareSIcon = require("../../FriendCircle/Img/FirendShare.png");
    ValueSuccess = false;
    active = false;

    mounted(){
        this.init()
    }

    init(){
        if (Number(this.ContValue.offsetHeight) > 57 || Number(this.ContValue.offsetHeight) > 60) {
            this.ValueSuccess = true
        }else{
            this.ValueSuccess = false
        }
        if ( Number(this.getData.isLike) ){
            this.active = true
        }else{
            this.active = false
        }
    }

    handleClickGive(): void {
        getGive(this.getData.id).then(res=>{
            if ( this.active ){
                this.getData.likeCount = Number(this.getData.likeCount) - 1 || 0
            }else{
                this.getData.likeCount = Number(this.getData.likeCount) + 1
            }
            this.active = !this.active
        })
    }

    handleClickShare(): void {
        Toast.fail("暂未开放该功能")
    }

    handleComment(): void {
        FriendSuccessStore.setCommentShow(true)
        this.$router.push({
            name:"friendSuccess",
            params:{
                data:this.getData,
                state:this.getState
            },
            query:{
                id:this.getData.id,
                state:this.getState,
                comment:'true'
            },
        })
    }

    handlePerView(e: any, index: number): void {
        ImagePreview({
            images: this.ImageList,
            startPosition: index
        })
        e.stopPropagation()
        e.preventDefault()
    }

    handleToSuccess(e: any): void {
        this.$router.push({
            name:"friendSuccess",
            params:{
                data:this.getData,
                state:this.getState
            },
            query:{
                id:this.getData.id,
                state:this.getState
            },
        })
        e.stopPropagation()
        e.preventDefault()
    }

    handleToUser(): void {
        this.$router.push({
            path: "/addFriend",
            query: {
                id: this.getData.userId,
            }
        })
    }

    get getTime(){
        return computedTime(this.getData.createTime) // getData.updateTime
    }

    get newState(){
        if ( this.data.userId ){
            let ID = Storage.GetData_ && Storage.GetData_("userId");
            if ( Number( this.getData.userId ) === Number( ID ) ){
                return false;
            }else{
                return true
            }
        }else{
            return true
        }
    }

    get ImageList(){
        if ( String(this.getData.image).length ){
            let Arr = String(this.getData.image).split(",")
            return Arr
        }else{
            return []
        }
    }

    @Prop(Object)
    data!:any;

    get getData(){
        return this.data
    }

    @Prop(String)
    state!:string

    get getState(){
        return this.state
    }

    @Prop(Number)
    index!:number

    get getIndex(){
        return this.index
    }

    @Ref("ContValue")
    ContValue:any
}
